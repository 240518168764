import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { api } from "api/API";
import appStore from "app/mobxStore/AppStore";
import CloseIcon from "@mui/icons-material/Close";
import { CloseButton } from "app/formComponents/MainDialog";
import {
  Box,
  Icon,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
interface Entity {
  entityId: number;
  entityName: string;
  entityLocation: string;
  entityPhone: string;
  entityDescription: string;
}

interface EntityDialogProps {
  onClose: () => void;
}

const EntityButton = styled(Button)`
  margin: 10px;
  min-width: 240px;
  justify-content: left;
  border: 1px solid #bdbdbd;
  &:hover {
    border: 1px solid #757575;
  }
  &:active {
    border: 1px solid #757575;
  }
`;

const ModalContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
  max-width: 620px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const EntityDialog: React.FC<EntityDialogProps> = observer(({ onClose }) => {
  const [entities, setEntities] = useState([]);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedEntity, setSelectedEntity] = useState<number | null>(null);

  useEffect(() => {
    setOpen(appStore.showEntityDialog);
  }, [`${appStore.showEntityDialog}`]);

  useEffect(() => {
    if (appStore.loginResponse.success) {
      api
        .get(`get-user-entities/${appStore.loginResponse.user[0].userId}`)
        .then((res) => {
          if (res.success) {
            if (res.data.length > 1 && appStore.selectedEntity === 1) {
              setOpen(false);
            } else {
              appStore.selectedEntity = res.data[0]?.entityId ?? 1;
            }
            const entites = res.data;
            appStore.setUserEntities(entites ?? []);
            setEntities(entites ?? []);
          }
        });
    }
  }, []);

  const filteredEntities = entities.filter((entity) =>
    entity.entityName.toLowerCase().includes(filter.toLowerCase())
  );
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          appStore.setShowEntityDialog(false);
        }}
      >
        <ModalContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography id="entity-dialog-title" sx={{ ml: "10px" }}>
              Select an Entity
            </Typography>
            <IconButton
              onClick={() => {
                appStore.selectedEntity = entities[0].entityId;
                appStore.setShowEntityDialog(false);
                setOpen(false);
              }}
              sx={{
                color: "grey.500",
                position: "relative",
                bottom: "15px",
                left: "12px",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <TextField
            sx={{
              marginBottom: "16px",
              width: "100%",
            }}
            variant="filled"
            placeholder="Filter entities..."
            onChange={(e) => setFilter(e.target.value)}
          />

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {filteredEntities.map((entity) => (
              <EntityButton
                key={entity.entityId}
                variant="outlined"
                onClick={() => {
                  setSelectedEntity(entity.entityId);
                  appStore.setSelectedEntity(entity.entityId);
                  appStore.setShowEntityDialog(false);
                  setOpen(false);
                }}
                sx={{
                  textAlign: "left",
                  border:
                    selectedEntity === entity.entityId ? "#ffab40" : "#eeeeee",
                }}
              >
                <IconButton>
                  <Icon sx={{ width: 36 }}>location_on</Icon>
                </IconButton>
                {entity.entityName}
              </EntityButton>
            ))}
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
});

export default EntityDialog;
