export const Themecolors = {
  // Main Backgrounds
  main_bg1: "#1a3d66", // Deep blue for primary background
  main_bg2: "#ffa726", // Vibrant orange for secondary background

  // Buttons
  Button1: "#ffa726", // Primary button background (orange)
  Button2: "#1a3d66", // Secondary button text (blue)
  Button3: "#1976d2", // Neutral button e0e0e0
  Button_bg1: "#ffa726", // Orange background
  Button_bg2: "#1976d2", // Blue background
  Button_bg3: "#f5f5f5", // Light gray background
  Button_bg4: "#eceff1", // Very light gray for disabled buttons
  Button_border1: "#ffa726", // Orange border
  Button_border2: "#1976d2", // Blue border
  button_hover1: "linear-gradient(90deg, #ffd95b 0%, #ffa726 100%)", // Orange hover gradient
  button_hover2: "linear-gradient(90deg, #64b5f6 0%, #1565c0 100%)", // Blue hover gradient
  button_disabled_bg: "#cfd8dc", // Muted gray for disabled buttons
  button_disabled_text: "#9e9e9e", // Disabled button text color

  // Headers
  H_text1: "#1565c0", // Blue for header text
  H_text2: "#ffa726", // Orange for header highlights
  H_text3: "#ffffff", // White text for headers
  H_hv: "linear-gradient(360deg, #ffffff 0%, #e0e0e0 100%)", // Subtle hover effect for headers

  // DataGrid
  Dg_bg1: "#ffa726", // Orange for background
  Dg_bg2: "#1a3d66", // Blue for header background
  Dg_Icon_bg3: "#f5f5f5", // Light gray for icon background
  Dg_text1: "#ffffff", // White for text in DataGrid
  Dg_text2: "#ffa726", // Orange for active text
  Dg_Icon1: "#ffa726", // Orange for icons
  Dg_Icon2: "#1a3d66", // Blue for icons

  // Dashboard
  DB_bg: "#f4f6f8", // Light gray for dashboard background
  DB_bg1: "#ffa726", // Dashboard orange highlight
  DB_Icon2: "#1976d2", // Blue for icons in the dashboard
  DB_text2: "#1565c0", // Blue for text
  DB_hv1: "linear-gradient(90deg, #ffd95b 0%, #ffa726 100%)", // Orange hover effect
  DB_hv3: "linear-gradient(90deg, #fafafa 0%, #e0e0e0 100%)", // Subtle ash hover effect

  // Forms and Inputs
  InputText_Color1: "#1976d2", // Blue text for inputs
  InputText_Color2: "#424242", // Neutral black text
  InputText_Color3: "#ffa726", // Orange text for inputs
  InputBorder1: "#1a3d66", // Blue border for inputs
  InputBorder2: "#ffa726", // Orange border for inputs

  // Upload Button
  UP_Button1: "#1a3d66", // Blue for upload button
  UP_Button_Border: "#ffa726", // Orange border for upload button

  // User Header Component
  UH_Icon1: "#ffa726", // Orange for icons
  UH_Icon2: "#1976d2", // Blue for icons
  UH_Icon_bg1: "#ffa726", // Orange background for icons
  UH_text1: "#ffffff", // White text
  UH_text2: "#ffa726", // Orange text
  UH_text3: "#1565c0", // Blue text
  UH_hv1: "linear-gradient(90deg, #ffb74d 0%, #ffa726 100%)", // Orange hover
  UH_hv2: "linear-gradient(90deg, #64b5f6 0%, #1976d2 100%)", // Blue hover

  // TabBar
  T_bg1: "#ffa726", // Orange tab background
  T_bg2: "#1a3d66", // Blue tab background

  // Form Fields
  F_bg2: "#1a3d66", // Blue background for form fields
  F_text1: "#ffffff", // White text for form fields

  // Radio Button
  RB_Selected2: "#ffa726", // Orange for selected radio button

  // Footer
  footer_bg: "#102027", // Dark blue-gray for footer background
  footer_text: "#b0bec5", // Light gray for footer text

  // Gradients
  B_hv1: "linear-gradient(90deg, #ffb74d 0%, #ffa726 100%)", // Orange button hover
  B_hv2: "linear-gradient(90deg, #64b5f6 0%, #1565c0 100%)", // Blue button hover
  B_hv3: "linear-gradient(90deg, #f5f5f5 0%, #e0e0e0 100%)", // Ash hover
  Error_Color: "#FF0000",
  Success_Color: "#00FF00",
};

export const fonts = {
  roboto: "'Roboto', sans-serif", // Modern sans-serif for general text
  poppins: "'Poppins', sans-serif", // Elegant and modern for headers
  lato: "'Lato', sans-serif", // Clean and versatile for body text
  montserrat: "'Montserrat', sans-serif", // Geometric sans-serif for headings
  open_sans: "'Open Sans', sans-serif", // Highly readable and professional
  inter: "'Inter', sans-serif", // Clean, highly legible for digital interfaces
};
